@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-BlackIt.woff2") format("woff2"),
    url("ProximaNova-BlackIt.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNovaA-LightIt.woff2") format("woff2"),
    url("ProximaNovaA-LightIt.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNovaA-Light.woff2") format("woff2"),
    url("ProximaNovaA-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-Regular.woff2") format("woff2"),
    url("ProximaNova-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNovaT-Thin.woff2") format("woff2"),
    url("ProximaNovaT-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-BoldIt.woff2") format("woff2"),
    url("ProximaNova-BoldIt.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-ThinIt.woff2") format("woff2"),
    url("ProximaNova-ThinIt.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-Bold.woff2") format("woff2"),
    url("ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNovaA-RegularIt.woff2") format("woff2"),
    url("ProximaNovaA-RegularIt.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
